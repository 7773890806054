import React from 'react'

import Layout from '../layouts'
import Iframe from 'react-iframe'

const Calender = () => (
  <Layout>
    <h1>Kalender</h1>

    <p>Du kan lägga till kalendern på din egen kalendervy <a href="https://calendar.google.com/calendar/embed?src=evolvetechnology.se_etle0gmv5no55olpms93ophsr0%40group.calendar.google.com&ctz=Europe%2FStockholm">här</a>.</p>
    
    <Iframe url="https://calendar.google.com/calendar/embed?src=evolvetechnology.se_etle0gmv5no55olpms93ophsr0%40group.calendar.google.com&ctz=Europe%2FStockholm"
        width="100%"
        height="450px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"
        allowFullScreen/>
    
  </Layout>
)

export default Calender
